import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'react-admin';
import type { AddSeasonButtonProps } from '../../interfaces';

const AddSeasonButton: React.FC<AddSeasonButtonProps> = ({ setOpen }) => {
  return (
    <Button color="primary" size="small" label="actions.addSeason" onClick={() => setOpen(true)}>
      <AddIcon />
    </Button>
  );
};

export default AddSeasonButton;
