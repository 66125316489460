import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import type { AxiosError } from 'axios';
import { isValid, isAfter, format, addHours } from 'date-fns';
import { useNotify, useRefresh } from 'react-admin';
import { useHandleApiError } from '../../hooks';
import { RefreshToken } from '../../service';

const RefreshTokenButton: React.FC = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const handleError = useHandleApiError();
  const tokenDate = localStorage.getItem('refresh-button-date') ?? '';

  const refreshToken = async (): Promise<string> => {
    const data = await RefreshToken();
    return data;
  };

  const handleClick = (): void => {
    refreshToken()
      .then(() => {
        localStorage.setItem(
          'refresh-button-date',
          format(addHours(new Date(), 22), 'yyyy/MM/dd HH:mm:ss'),
        );
        notify('success.refreshTokenSet', { type: 'success' });
        refresh();
      })
      .catch(async (err: AxiosError) => {
        await handleError(err);
      });
  };

  return (
    <>
      {tokenDate.length > 0 &&
      isValid(new Date(tokenDate)) &&
      isAfter(new Date(), new Date(tokenDate)) ? (
        /* eslint-disable indent */
        <Tooltip title="Refresh auth token">
          <Fab
            color="primary"
            onClick={handleClick}
            sx={{
              color: '#fff',
              position: 'fixed',
              bottom: 30,
              right: 16,
            }}
          >
            <RefreshIcon />
          </Fab>
        </Tooltip>
      ) : null}
      {/* eslint-enable indent */}
    </>
  );
};

export default RefreshTokenButton;
