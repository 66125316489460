import type { AxiosError } from 'axios';
import { format, addHours } from 'date-fns';
import { type AuthProvider } from 'react-admin';
import { IsLogged, Login, Logout } from '../service';

/**
 * Handles all authentication in react-admin.
 */
const authProvider: AuthProvider = {
  login: async ({ login, password }) => {
    const username = await Login(login, password).catch((err: AxiosError) => {
      if (err.response?.status === 401 || err.response?.status === 403) throw err;
    });
    localStorage.setItem('logged', 'true');
    localStorage.setItem(
      'refresh-button-date',
      format(addHours(new Date(), 22), 'yyyy/MM/dd HH:mm:ss'),
    );
    localStorage.setItem('username', username ?? '');
  },
  logout: async () => {
    localStorage.removeItem('logged');
    localStorage.removeItem('refresh-button-date');
    localStorage.removeItem('username');
    await Logout();
  },
  checkAuth: async () => {
    const storageData = localStorage.getItem('logged') ?? '';
    if (storageData.length > 0 && storageData === 'true') {
      try {
        const isLogged = await IsLogged();
        if (isLogged) {
          return;
        } else {
          throw new Error('errors.userLoggedOut');
        }
      } catch {
        throw new Error('errors.userLoggedOut');
      }
    }
    throw new Error('errors.userLoggedOut');
  },
  checkError: async (err: AxiosError) => {
    if (err.response?.status === 401 || err.response?.status === 403) {
      localStorage.removeItem('logged');
      localStorage.removeItem('refresh-button-date');
      await Promise.reject(new Error('errors.userLoggedOut'));
    }
    await Promise.resolve();
  },
  getPermissions: async () => {
    await Promise.resolve();
  },
};

export default authProvider;
