import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { List, Datagrid, SimpleList, TextField, ReferenceField, type ListProps } from 'react-admin';
import CustomEmpty from '../../cms-core/src/layout/CustomEmpty';
import { trophyFilters } from '../../components/filters/trophy';
import CustomActions from '../../layout/CustomActions';
import CustomBulkActions from '../../layout/CustomBulkActions';

const TrophyList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomActions hasFilters hasCreate resource="trophy" />}
      filters={trophyFilters(isSmall)}
      empty={<CustomEmpty resource="trophy" />}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall ? (
        <Datagrid rowClick="edit" bulkActionButtons={<CustomBulkActions />}>
          <TextField source="name" />
          <ReferenceField source="fifaId" label="FIFA" reference="fifa" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      ) : (
        <SimpleList primaryText={(data) => data.name} linkType="edit" />
      )}
    </List>
  );
};

export default TrophyList;
