import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import type { AxiosError } from 'axios';
import { useDataProvider, useRecordContext, useNotify, useTranslate } from 'react-admin';
import SquadPlayersFieldCard from './SquadPlayersFieldCard';
import SquadPlayersSubsDialog from './SquadPlayersSubsDialog';
import { useHandleApiError } from '../../../cms-core/src/hooks';
import type { PlayerInSquad, Player, PlayerWithOrder } from '../../../interfaces';
import { EditSquadPlayers, GetSquadPlayers } from '../../../services/DataService';
import Loader from '../../utils/Loader';

const SquadPlayersField: React.FC = () => {
  const [playersInSquad, setPlayersInSquad] = React.useState<PlayerInSquad[]>([]);
  const [playersInSquadCopy, setPlayersInSquadCopy] = React.useState<PlayerInSquad[]>([]);
  const [players, setPlayers] = React.useState<PlayerWithOrder[]>([]);
  const [playerList, setPlayerList] = React.useState<Player[]>([]);
  const [defenders, setDefenders] = React.useState<number>(0);
  const [midfielders, setMidfielders] = React.useState<number>(0);
  const [attackers, setAttackers] = React.useState<number>(0);
  const [layout, setLayout] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const notify = useNotify();
  const translate = useTranslate();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const handleError = useHandleApiError();
  const { id, formationId, fifaId } = record ?? { id: 0, formationId: 0, fifaId: 0 };

  const returnStandardGrid = (amount: number, type: string): JSX.Element[] => {
    let slicedPlayers: PlayerWithOrder[] = [];
    /* slice array items to specific grids */
    if (type === 'gk') slicedPlayers = players.slice(0, 1);
    if (type === 'def') slicedPlayers = players.slice(1, defenders + 1);
    if (type === 'mid') slicedPlayers = players.slice(defenders + 1, midfielders + defenders + 1);
    if (type === 'att') slicedPlayers = players.slice(midfielders + defenders + 1);

    const components = [];
    for (let i = 0; i < amount; i++) {
      const currentPlayer = slicedPlayers[i];
      components.push(
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          key={i}
          sx={{
            margin: '1rem auto 0px',
          }}
        >
          {slicedPlayers.length > 0 ? (
            <SquadPlayersFieldCard
              fifa={fifaId}
              currentPlayer={currentPlayer}
              playersInSquad={playersInSquad}
              setPlayersInSquad={setPlayersInSquad}
              setRefresh={setRefresh}
              playerList={playerList}
            />
          ) : null}
        </Grid>,
      );
    }
    return components;
  };

  const returnCustomGrid = (amount: number, type: string): JSX.Element[] => {
    let slicedPlayers: PlayerWithOrder[] = [];
    /* slice array items to specific grids */
    if (type === 'cdm') slicedPlayers = players.slice(defenders + 1, defenders + amount + 1);
    if (type === 'cm')
      slicedPlayers = players.slice(defenders + amount + 1, defenders + amount + 2);
    if (type === 'cam')
      slicedPlayers = players.slice(defenders + amount + 2, defenders + amount + 3);
    const components = [];
    for (let i = 0; i < amount; i++) {
      const currentPlayer = slicedPlayers[i];
      components.push(
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          key={i}
          sx={{
            margin: '1rem auto 0px',
          }}
        >
          {slicedPlayers.length > 0 ? (
            <SquadPlayersFieldCard
              fifa={fifaId}
              currentPlayer={currentPlayer}
              playersInSquad={playersInSquad}
              setPlayersInSquad={setPlayersInSquad}
              setRefresh={setRefresh}
              playerList={playerList}
            />
          ) : null}
        </Grid>,
      );
    }
    return components;
  };

  const updatePlayersCall = async (players: PlayerInSquad[]): Promise<void> => {
    await EditSquadPlayers(players);
    notify('success.squadSaved', { type: 'success' });
    setPlayersInSquadCopy(playersInSquad);
  };

  const updatePlayers = (): void => {
    /* get new players */
    const onlyNewPlayers = playersInSquad.filter(
      (player: PlayerInSquad) =>
        !playersInSquadCopy.some(
          (entity: PlayerInSquad) =>
            player.playerId === entity.playerId && player.orderInSquad === entity.orderInSquad,
        ),
    );
    updatePlayersCall(onlyNewPlayers).catch(async (err: AxiosError) => {
      handleError(err);
    });
  };

  /* display updated players before save */
  const getUpdatedPlayersPreview = React.useCallback(async () => {
    const onlyIds = playersInSquad.map((entity: PlayerInSquad) => entity.playerId);
    const playersData = await dataProvider.getMany('player', { ids: onlyIds });
    /* add correct order to player object based on id of resp object */
    const withOrder = playersData.data.map((player: Player) => {
      const match = playersInSquad.find(
        (playerInSquad: PlayerInSquad) => playerInSquad.playerId === player.id,
      );
      return match !== undefined
        ? /* eslint-disable indent */
          {
            ...player,
            order: match?.orderInSquad,
          }
        : player;
      /* eslint-enable indent */
    });
    /* sort players by order */
    const sorted = withOrder.sort(
      (a: PlayerWithOrder, b: PlayerWithOrder) => (a.order ?? 0) - (b.order ?? 0),
    );
    setPlayers(sorted);
    setRefresh(false);
  }, [dataProvider, playersInSquad]);

  /* get players in squad */
  const getPlayers = React.useCallback(async () => {
    const data = await GetSquadPlayers(id);
    setPlayersInSquad(data);
    setPlayersInSquadCopy(data);
    const onlyIds = data.map((entity: PlayerInSquad) => entity.playerId);
    const playersData = await dataProvider.getMany('player', { ids: onlyIds });
    /* add correct order to player object based on id of resp object */
    const withOrder = playersData.data.map((player: Player) => {
      const match = data.find(
        (playerInSquad: PlayerInSquad) => playerInSquad.playerId === player.id,
      );
      /* eslint-disable indent */
      return match !== undefined
        ? {
            ...player,
            order: match?.orderInSquad,
          }
        : player;
      /* eslint-enable indent */
    });
    /* sort players by order */
    const sorted = withOrder.sort(
      (a: PlayerWithOrder, b: PlayerWithOrder) => (a.order ?? 0) - (b.order ?? 0),
    );
    setPlayers(sorted);
    setLoading(false);
  }, [dataProvider, id]);

  /* get formation data */
  const getFormation = React.useCallback(async () => {
    const data = await dataProvider.getOne('formation', { id: formationId });
    const { defenders, midfielders, attackers, layout } = data.data;
    setDefenders(defenders);
    setMidfielders(midfielders);
    setAttackers(attackers);
    setLayout(layout);
  }, [dataProvider, formationId]);

  /* get all players */
  const getAllPlayers = React.useCallback(async () => {
    const data = await dataProvider.getList('player', {
      pagination: { page: 1, perPage: 1000000 },
      sort: { field: 'id', order: 'ASC' },
      filter: { fifaId },
    });
    setPlayerList(data.data);
  }, [dataProvider, fifaId]);

  React.useEffect(() => {
    if (refresh) {
      getUpdatedPlayersPreview().catch(async (err: AxiosError) => {
        await handleError(err);
      });
    }
  }, [refresh, getUpdatedPlayersPreview, handleError]);

  React.useEffect(() => {
    if (record !== undefined) {
      /* get player list */
      getAllPlayers().catch(async (err: AxiosError) => {
        await handleError(err);
      });
      /* get players data */
      getPlayers().catch(async (err: AxiosError) => {
        await handleError(err);
      });
      /* get formation data */
      getFormation().catch(async (err: AxiosError) => {
        await handleError(err);
      });
    }
  }, [record, getAllPlayers, getFormation, getPlayers, handleError]);

  return (
    <>
      {!loading ? (
        <>
          <Grid container>{returnStandardGrid(attackers, 'att')}</Grid>
          {layout.split('-').length === 5 ? (
            <>
              <Grid container>{returnCustomGrid(parseInt(layout.split('-')[3]), 'cam')}</Grid>
              <Grid container>{returnCustomGrid(parseInt(layout.split('-')[2]), 'cm')}</Grid>
              <Grid container>{returnCustomGrid(parseInt(layout.split('-')[1]), 'cdm')}</Grid>
            </>
          ) : (
            <Grid container>{returnStandardGrid(midfielders, 'mid')}</Grid>
          )}
          <Grid container>{returnStandardGrid(defenders, 'def')}</Grid>
          <Grid container>{returnStandardGrid(1, 'gk')}</Grid>
          <Grid container>
            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Tooltip title={translate('resources.squad.fields.subs')}>
                  <IconButton onClick={() => setOpenDialog(true)}>
                    <AddCircleIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ marginTop: '1rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={updatePlayers}
                  startIcon={<SaveIcon />}
                  disabled={playersInSquad.length !== 18}
                  sx={{
                    color: '#fff',
                  }}
                >
                  {translate('ra.action.save')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Loader size={50} />
      )}
      {openDialog ? (
        <SquadPlayersSubsDialog
          open={openDialog}
          setOpen={setOpenDialog}
          playersInSquad={playersInSquad}
          playerList={playerList}
          fifa={fifaId}
        />
      ) : null}
    </>
  );
};

export default SquadPlayersField;
