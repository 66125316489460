import React from 'react';
import type { Identifier } from 'react-admin';
import type { SquadContextProps, SquadProviderProps } from '../interfaces';

export const SquadContext = React.createContext<SquadContextProps | null>(null);

/**
 * Provider for SquadContext
 */
export const SquadProvider: React.FC<SquadProviderProps> = ({ children }) => {
  const [level, setLevel] = React.useState<number>(1);
  const [fifa, setFifa] = React.useState<Identifier | null>(null);

  return (
    <SquadContext.Provider value={{ level, setLevel, fifa, setFifa }}>
      {children}
    </SquadContext.Provider>
  );
};
