import React from 'react';
import Avatar from '@mui/material/Avatar';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';
import { type AvatarFieldProps } from '../../interfaces';
import { buildFifaIndexUrl } from '../../utils';

const AvatarField: React.FC<AvatarFieldProps> = ({ source, type, size }) => {
  const record = useRecordContext();

  const fifaIndexId = React.useMemo(() => {
    if (!record) return 0;
    return get(record, source, 0);
  }, [record, source]);

  const fifaVersion = React.useMemo(() => {
    if (!record) return 0;
    return get(record, ['fifaId']);
  }, [record]);

  const imgSrc = React.useMemo(() => {
    return buildFifaIndexUrl(fifaVersion, fifaIndexId, type);
  }, [fifaIndexId, fifaVersion, type]);

  return <Avatar alt="avatar" src={imgSrc} sx={{ width: size, height: size }} />;
};

export default AvatarField;
