import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, type ShowProps } from 'react-admin';
import CustomActions from '../../layout/CustomActions';
import ManagerTitle from '../../layout/ManagerTitle';

const ManagerShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      title={<ManagerTitle view="show" />}
      actions={<CustomActions resource="manager" hasEdit hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <ReferenceField source="fifaId" reference="fifa" link={false}>
          <TextField source="name" label="FIFA" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export default ManagerShow;
