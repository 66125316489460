import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TopToolbar, Button, useRedirect } from 'react-admin';
import { useIsAdmin } from '../cms-core/src/hooks';
import CoreActionButtons from '../cms-core/src/layout/CoreActionButtons';
import AddSeasonButton from '../components/buttons/AddSeasonButton';
import CustomCreateButton from '../components/buttons/CustomCreateButton';
import FavouriteButton from '../components/buttons/FavouriteButton';
import type { CustomActionsProps } from '../interfaces';
import { adminResources } from '../utils';

const AddSeasonDialog = React.lazy(() => import('../components/career/AddSeasonDialog'));

const CustomActions: React.FC<CustomActionsProps> = ({
  resource,
  hasEdit,
  hasList,
  hasShow,
  hasCreate,
  hasFilters,
  hasChangePass,
  hasAddSeason,
  setRefreshSeasons,
  hasFavourite,
  hasCreateSquad,
  hasImportCreate,
}) => {
  const [openAddSeason, setOpenAddSeason] = React.useState<boolean>(false);
  const isAdmin = useIsAdmin();
  const redirect = useRedirect();

  return (
    <>
      <TopToolbar>
        <CoreActionButtons
          resource={resource}
          hasEdit={hasEdit}
          hasList={hasList}
          hasShow={hasShow}
          hasCreate={hasCreate}
          hasFilters={hasFilters}
          hasChangePass={hasChangePass}
          adminResources={adminResources}
        />
        {hasAddSeason && <AddSeasonButton setOpen={setOpenAddSeason} />}
        {hasFavourite && <FavouriteButton />}
        {hasImportCreate && (!adminResources.includes(resource) || isAdmin) && (
          <CustomCreateButton
            resource={resource}
            hasMenu={resource === 'player' || resource === 'team'}
          />
        )}
        {hasCreateSquad && (
          <Button
            color="primary"
            size="small"
            onClick={() => redirect('/squad-builder')}
            label="Create"
          >
            <AddIcon />
          </Button>
        )}
      </TopToolbar>
      {openAddSeason && (
        <AddSeasonDialog
          open={openAddSeason}
          setOpen={setOpenAddSeason}
          setRefreshSeasons={setRefreshSeasons}
        />
      )}
    </>
  );
};

export default CustomActions;
