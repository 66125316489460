import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { List, Datagrid, SimpleList, TextField, NumberField, type ListProps } from 'react-admin';
import CustomEmpty from '../../cms-core/src/layout/CustomEmpty';
import { formationFilters } from '../../components/filters/formation';
import CustomActions from '../../layout/CustomActions';
import CustomBulkActions from '../../layout/CustomBulkActions';

const FormationList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomActions hasFilters hasCreate resource="formation" />}
      filters={formationFilters(isSmall)}
      empty={<CustomEmpty resource="formation" />}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall ? (
        <Datagrid rowClick="show" bulkActionButtons={<CustomBulkActions />}>
          <NumberField source="id" />
          <TextField source="name" />
          <TextField source="layout" />
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(data) => data.name}
          secondaryText={(data) => data.layout}
          linkType="show"
        />
      )}
    </List>
  );
};

export default FormationList;
