import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTranslate } from 'react-admin';
import FifaVersionDialog from './FifaVersionDialog';

const FifaMenuItem: React.FC = () => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const translate = useTranslate();

  return (
    <>
      <MenuItem onClick={() => setOpenDialog(true)}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{translate('resources.fifa.fields.version')}</ListItemText>
      </MenuItem>
      {openDialog ? <FifaVersionDialog open={openDialog} setOpen={setOpenDialog} /> : null}
    </>
  );
};

export default FifaMenuItem;
