import React from 'react';
import { Show, SimpleShowLayout, TextField, NumberField, type ShowProps } from 'react-admin';
import CustomTitle from '../../cms-core/src/layout/CustomTitle';
import CustomActions from '../../layout/CustomActions';

const FormationShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      title={<CustomTitle resource="formation" source="name" view="show" />}
      actions={<CustomActions resource="formation" hasEdit hasList />}
      sx={{ marginBottom: '1rem' }}
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="name" />
        <NumberField source="defenders" />
        <NumberField source="midfielders" />
        <NumberField source="attackers" />
        <TextField source="layout" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FormationShow;
