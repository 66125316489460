import React from 'react';
import Box from '@mui/material/Box';
import Loader from './Loader';

/**
 * React component used when wrapping react-admin App component in Suspense
 */
const SuspenseFallback: React.FC = () => {
  return (
    <Box
      data-testid="suspense-box"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Loader size={70} />
    </Box>
  );
};

export default SuspenseFallback;
