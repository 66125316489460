import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  ReferenceField,
  type ShowProps,
} from 'react-admin';
import CustomTitle from '../../cms-core/src/layout/CustomTitle';
import CustomActions from '../../layout/CustomActions';

const PositionShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<CustomTitle resource="position" source="name" view="show" />}
      actions={<CustomActions resource="position" hasEdit hasList />}
      sx={{ marginBottom: '1rem' }}
    >
      <SimpleShowLayout>
        <NumberField source="id" />
        <TextField source="name" />
        <ReferenceField source="positionGroupId" reference="position_group" link="show">
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export default PositionShow;
