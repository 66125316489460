import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
  type ShowProps,
} from 'react-admin';
import FifaIndexUrlField from '../../components/fields/FifaIndexUrlField';
import ImageField from '../../components/fields/ImageField';
import CustomActions from '../../layout/CustomActions';
import PlayerTitle from '../../layout/PlayerTitle';

const PlayerShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<PlayerTitle view="show" />}
      actions={<CustomActions resource="player" hasFavourite hasEdit hasList />}
      sx={{
        marginBottom: '1rem',
      }}
    >
      <SimpleShowLayout>
        <TextField source="firstName" />
        <FunctionField
          source="lastName"
          label="LastName"
          render={(record) => (record.lastName !== null ? record.lastName : 'Empty')}
        />
        <ImageField source="fifaIndexId" alt="game-face" type="players" label="Game face" />
        <ReferenceField source="teamId" reference="team" link="show">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="age" />
        <NumberField source="fifaIndexId" />
        <ReferenceField source="positionId" reference="position" link="show">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="rating" />
        <NumberField source="rating_potential" label="Potentional rating" />
        <FifaIndexUrlField label="Fifa Index Page" type="player" />
        <ReferenceField source="fifaId" label="FIFA" reference="fifa" link={false}>
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export default PlayerShow;
