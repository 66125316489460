import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const ComingSoonPage: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage:
          'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontSize: '50px',
              color: '#fff',
              marginTop: '6rem',
              fontFamily: 'Gilroy',
            }}
          >
            Squad Builder
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            variant="h5"
            component="span"
            align="center"
            sx={{
              color: '#fff',
              fontFamily: 'Gilroy',
              marginTop: '3rem',
            }}
          >
            The new squad builder tool for retro FIFA.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            variant="h6"
            component="span"
            align="center"
            sx={{
              color: '#fff',
              fontFamily: 'Gilroy',
              marginTop: '1rem',
            }}
          >
            Coming soon.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComingSoonPage;
