import { SearchInput } from 'react-admin';

export const fifaFilters = (isSmall: boolean): JSX.Element[] => {
  return [
    <SearchInput
      source="name"
      variant="filled"
      key="name"
      alwaysOn={!isSmall}
      sx={{ marginLeft: isSmall ? '10px' : 0 }}
    />,
  ];
};
