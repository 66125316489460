import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  List,
  Datagrid,
  SimpleList,
  NumberField,
  TextField,
  ReferenceField,
  type ListProps,
} from 'react-admin';
import CustomEmpty from '../../cms-core/src/layout/CustomEmpty';
import { positionFilters } from '../../components/filters/position';
import CustomActions from '../../layout/CustomActions';
import CustomBulkActions from '../../layout/CustomBulkActions';

const PositionList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomActions hasFilters hasCreate resource="position" />}
      filters={positionFilters(isSmall)}
      empty={<CustomEmpty resource="position" />}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall ? (
        <Datagrid rowClick="show" bulkActionButtons={<CustomBulkActions />}>
          <NumberField source="id" />
          <TextField source="name" />
          <ReferenceField source="positionGroupId" reference="position_group" link="show">
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      ) : (
        <SimpleList primaryText={(data) => data.name} linkType="show" />
      )}
    </List>
  );
};

export default PositionList;
