import React from 'react';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useTheme, type Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { AxiosError } from 'axios';
import { useRedirect, useDataProvider, useTranslate } from 'react-admin';
import SquadPlayersFieldDialog from './SquadPlayersFieldDialog';
import { useHandleApiError } from '../../../cms-core/src/hooks';
import type { SquadPlayersFieldCardProps, PlayerInSquad } from '../../../interfaces';
import { buildFifaIndexUrl } from '../../../utils';

const SquadPlayersFieldCard: React.FC<SquadPlayersFieldCardProps> = ({
  fifa,
  currentPlayer,
  playersInSquad,
  setPlayersInSquad,
  setRefresh,
  playerList,
}) => {
  const [teamAvatar, setTeamAvatar] = React.useState<string>('');
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [hasSubs, setHasSubs] = React.useState<boolean>(false);
  const [dialogType, setDialogType] = React.useState<string>('');

  const theme = useTheme();
  const redirect = useRedirect();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const handleError = useHandleApiError();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleDialogOpen = (type: string): void => {
    setDialogType(type);
    setOpenDialog(type === 'subs' ? hasSubs : true);
  };

  const getData = React.useCallback(async () => {
    const data = await dataProvider.getOne('team', { id: currentPlayer.teamId });
    setTeamAvatar(buildFifaIndexUrl(fifa, data.data.fifaIndexId, 'teams'));
  }, [dataProvider, fifa, currentPlayer.teamId]);

  React.useEffect(() => {
    getData().catch(async (err: AxiosError) => {
      await handleError(err);
    });
  }, [getData, handleError]);

  React.useEffect(() => {
    const filtered = playersInSquad.filter((player: PlayerInSquad) => player.sub === 1);
    setHasSubs(filtered.length > 0);
  }, [playersInSquad]);

  return (
    <>
      <Card
        raised
        sx={{
          width: isSmall ? '100%' : '95%',
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Tooltip title={translate('actions.replaceBench')}>
              <IconButton size="small" onClick={() => handleDialogOpen('subs')}>
                <ManageAccountsIcon
                  sx={{
                    color: hasSubs ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.26)',
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={translate('actions.replaceNewPlayer')}>
              <IconButton size="small" onClick={() => handleDialogOpen('new')}>
                <ChangeCircleIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Badge
                badgeContent={
                  <Typography sx={{ color: '#fff' }}>{currentPlayer.rating}</Typography>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                color="primary"
              >
                <Avatar
                  src={buildFifaIndexUrl(fifa, currentPlayer.fifaIndexId, 'players')}
                  onClick={() => redirect('show', 'player', currentPlayer.id)}
                  sx={{
                    width: 70,
                    height: 70,
                    margin: 'auto',
                    cursor: 'pointer',
                  }}
                />
              </Badge>
            </Box>
            <Box sx={{ marginTop: '1rem' }}>
              <Typography align="center">
                {`${currentPlayer.firstName} ${currentPlayer.lastName ?? ''}`}
              </Typography>
              <Avatar
                src={teamAvatar}
                sx={{
                  width: 54,
                  height: 54,
                  margin: 'auto',
                }}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
      {openDialog ? (
        <SquadPlayersFieldDialog
          open={openDialog}
          setOpen={setOpenDialog}
          order={currentPlayer.order ?? 0}
          playersInSquad={playersInSquad}
          setPlayersInSquad={setPlayersInSquad}
          setRefresh={setRefresh}
          playerList={playerList}
          dialogType={dialogType}
        />
      ) : null}
    </>
  );
};

export default SquadPlayersFieldCard;
