import React from 'react';
import PersonIcon from '@mui/icons-material/Person';

const UserList = React.lazy(() => import('./List'));
const UserEdit = React.lazy(() => import('./Edit'));
const UserCreate = React.lazy(() => import('./Create'));

/**
 * User resource
 */
const User = {
  name: 'user',
  icon: PersonIcon,
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
};

export default User;
