import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  SimpleList,
  ReferenceField,
  type ListProps,
} from 'react-admin';
import CustomEmpty from '../../cms-core/src/layout/CustomEmpty';
import AvatarField from '../../components/fields/AvatarField';
import { teamFilters } from '../../components/filters/team';
import CustomActions from '../../layout/CustomActions';
import CustomBulkActions from '../../layout/CustomBulkActions';

const TeamList: React.FC<ListProps> = (props) => {
  const defaultFifaFilter: string = localStorage.getItem('selected-fifa') ?? '';
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomActions hasFilters hasImportCreate resource="team" />}
      filters={teamFilters(isSmall)}
      filterDefaultValues={
        defaultFifaFilter.length > 0 ? { fifaId: parseInt(defaultFifaFilter) } : {}
      }
      empty={<CustomEmpty resource="team" />}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall ? (
        <Datagrid rowClick="show" bulkActionButtons={<CustomBulkActions />}>
          <TextField source="name" />
          <AvatarField source="fifaIndexId" type="teams" size={50} label="" />
          <NumberField source="budget" label="Budget (€)" />
          <ReferenceField source="rivalTeamId" reference="team" link="show">
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(data) => data.name}
          secondaryText={(data) => data.budget.toLocaleString()}
          linkType="show"
        />
      )}
    </List>
  );
};

export default TeamList;
