import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { List, Datagrid, SimpleList, TextField, ReferenceField, type ListProps } from 'react-admin';
import CustomEmpty from '../../cms-core/src/layout/CustomEmpty';
import { squadFilters } from '../../components/filters/squad';
import CustomActions from '../../layout/CustomActions';
import CustomBulkActions from '../../layout/CustomBulkActions';

const SquadList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomActions hasFilters hasCreateSquad resource="squad" />}
      filters={squadFilters(isSmall)}
      empty={<CustomEmpty resource="squad" />}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall ? (
        <Datagrid rowClick="show" bulkActionButtons={<CustomBulkActions />}>
          <TextField source="name" />
          <ReferenceField source="formationId" reference="formation" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="fifaId" label="FIFA" reference="fifa" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      ) : (
        <SimpleList primaryText={(data) => data.name} linkType="show" />
      )}
    </List>
  );
};

export default SquadList;
