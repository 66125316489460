import { lazy } from 'react';
import WorkIcon from '@mui/icons-material/Work';
import CareerCreate from './Create';
import CareerEdit from './Edit';
// import CareerList from './List';

const CareerList = lazy(() => import('./List'));
const CareerShow = lazy(() => import('./Show'));

const Career = {
  name: 'career',
  icon: WorkIcon,
  list: CareerList,
  show: CareerShow,
  edit: CareerEdit,
  create: CareerCreate,
};

export default Career;
