import React from 'react';
import Box from '@mui/material/Box';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';
import { type ImageFieldProps } from '../../interfaces';
import { buildFifaIndexUrl } from '../../utils';

const ImageField: React.FC<ImageFieldProps> = ({ source, alt, type }) => {
  const record = useRecordContext();

  const fifaIndexId: number = React.useMemo(() => {
    if (!record) return 0;
    return get(record, source, 0);
  }, [record, source]);

  const fifaVersion: number = React.useMemo(() => {
    if (!record) return 0;
    return get(record, ['fifaId']);
  }, [record]);

  const imgSrc = React.useMemo(() => {
    return buildFifaIndexUrl(fifaVersion, fifaIndexId, type);
  }, [fifaIndexId, fifaVersion, type]);

  return (
    <Box>
      <img src={imgSrc} title={alt} alt={alt} />
    </Box>
  );
};

export default ImageField;
