import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  List,
  Datagrid,
  SimpleList,
  FunctionField,
  ReferenceField,
  TextField,
  type ListProps,
  type RaRecord,
} from 'react-admin';
import CustomEmpty from '../../cms-core/src/layout/CustomEmpty';
import { managerFilters } from '../../components/filters/manager';
import CustomActions from '../../layout/CustomActions';
import CustomBulkActions from '../../layout/CustomBulkActions';

const ManagerList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomActions hasFilters hasCreate resource="manager" />}
      filters={managerFilters(isSmall)}
      empty={<CustomEmpty resource="manager" />}
      sort={{
        field: 'lastName',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall ? (
        <Datagrid rowClick="show" bulkActionButtons={<CustomBulkActions />}>
          <FunctionField
            source="lastName"
            label="Name"
            render={(record: RaRecord) => `${record.firstName} ${record.lastName}`}
          />
          <ReferenceField source="fifaId" reference="fifa" link={false}>
            <TextField source="name" label="FIFA" />
          </ReferenceField>
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(data: RaRecord) => `${data.firstName} ${data.lastName}`}
          linkType="show"
        />
      )}
    </List>
  );
};

export default ManagerList;
