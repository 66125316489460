import React from 'react';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTranslate, useLogout } from 'react-admin';
import { useLoggedUser } from '../hooks';

/**
 * React component that renders custom logout button (to clean the context).
 */
const CustomLogout: React.FC = () => {
  const logoutRa = useLogout();
  const translate = useTranslate();
  const { logout } = useLoggedUser();

  const handleLogout = (): void => {
    logout();
    logoutRa();
  };

  return (
    <MenuItem onClick={handleLogout}>
      <ListItemIcon>
        <ExitIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText data-testid="logout">{translate('ra.auth.logout')}</ListItemText>
    </MenuItem>
  );
};

export default CustomLogout;
