import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  useRedirect,
  type CreateProps,
} from 'react-admin';
import { useIsAdmin } from '../../cms-core/src/hooks';
import CustomActions from '../../layout/CustomActions';

const FifaCreate: React.FC<CreateProps> = (props) => {
  const isAdmin = useIsAdmin();
  const redirect = useRedirect();

  React.useEffect(() => {
    if (!isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect]);

  return (
    <Create
      redirect="list"
      actions={<CustomActions resource="fifa" hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput
          source="name"
          variant="filled"
          validate={required()}
          sx={{
            width: 219,
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default FifaCreate;
