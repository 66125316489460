import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import type { LoaderProps } from '../../interfaces';

/**
 * React component that displays loader element.
 *
 * @prop {number} size - The size of the loader.
 */
const Loader: React.FC<LoaderProps> = ({ size }) => {
  return <CircularProgress color="primary" size={size} />;
};

export default Loader;
