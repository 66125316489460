import React from 'react';
import { ListButton, ShowButton, EditButton, CreateButton, FilterButton } from 'react-admin';
import ChangePasswordActionButton from '../components/buttons/ChangePasswordActionButton';
import { useIsAdmin } from '../hooks';
import type { CoreActionButtonsProps } from '../interfaces';

/**
 * React component that renders action buttons for List, Show and Edit views. Can be wrapped in a component that uses TopToolbar.
 *
 * @prop {string} resource - Type of entity.
 * @prop {boolean | undefined} hasEdit - Indicates if EditButton will be displayed.
 * @prop {boolean | undefined} hasList - Indicates if ListButton will be displayed.
 * @prop {boolean | undefined} hasShow - Indicates if ShowButton will be displayed.
 * @prop {boolean | undefined} hasCreate - Indicates if CreateButton will be displayed.
 * @prop {boolean | undefined} hasFilters - Indicates if FilterButton will be displayed.
 * @prop {boolean | undefined} hasChangePass - Indicates if ChangePasswordActionButton will be displayed.
 * @prop {string[]} adminResources - Defines the resources that will only be available to admin (edit/create views).
 */
const CoreActionButtons: React.FC<CoreActionButtonsProps> = ({
  resource,
  hasEdit,
  hasList,
  hasShow,
  hasCreate,
  hasFilters,
  hasChangePass,
  adminResources,
}) => {
  const isAdmin = useIsAdmin();
  return (
    <>
      {hasFilters && <FilterButton resource={resource} disableSaveQuery />}
      {hasEdit && (!adminResources.includes(resource) || isAdmin) && (
        <EditButton resource={resource} />
      )}
      {hasList && <ListButton resource={resource} />}
      {hasShow && <ShowButton resource={resource} />}
      {hasCreate && (!adminResources.includes(resource) || isAdmin) && (
        <CreateButton resource={resource} />
      )}
      {hasChangePass && isAdmin ? <ChangePasswordActionButton /> : null}
    </>
  );
};

export default CoreActionButtons;
