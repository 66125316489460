import React from 'react';
import InboxIcon from '@mui/icons-material/Inbox';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { CreateButton, useTranslate } from 'react-admin';
import type { CustomEmptyProps } from '../interfaces';

/**
 * React component that renders empty component of specific resource.
 *
 * @prop {string} resource - Name of the resource.
 */
const CustomEmpty: React.FC<CustomEmptyProps> = ({ resource }) => {
  const theme = useTheme();
  const translate = useTranslate();

  return (
    <Box textAlign="center" m={1}>
      <InboxIcon
        fontSize="medium"
        data-testid="inbox-icon"
        sx={{ width: '9em', height: '9em', color: theme.palette.grey[500] }}
      />
      <Typography variant="h4" paragraph>
        {translate(`resources.${resource}.empty`)}
      </Typography>
      {resource !== 'log' && (
        <CreateButton resource={resource} variant="contained" data-testid="create-button" />
      )}
    </Box>
  );
};

export default CustomEmpty;
