import axios from 'axios';
import * as Dompurify from 'dompurify';
import { type Identifier, type RaRecord } from 'react-admin';
import type { PlayerInSquad } from '../interfaces';

const apiUrl = process.env.REACT_APP_API_URL ?? '';
axios.defaults.withCredentials = true;

/* REACT-ADMIN CRUD */
export * from '../cms-core/src/service';

/* OTHER ENDPOINTS */
export const AddPlayersToSquad = async (players: RaRecord[]): Promise<RaRecord[]> => {
  const data = await axios.post(`${apiUrl}/playerinsquad/add-players-to-squad`, players);
  return data.data;
};

export const GetSquadPlayers = async (id: Identifier): Promise<PlayerInSquad[]> => {
  const data = await axios.get(
    `${apiUrl}/playerinsquad/get-squad-players?id=${Dompurify.sanitize(id.toString())}`,
  );
  return data.data;
};

export const EditSquadPlayers = async (players: PlayerInSquad[]): Promise<string> => {
  const data = await axios.put(`${apiUrl}/playerinsquad/edit-squad-players`, players);
  return data.data;
};

export const GetLatestPlayers = async (): Promise<RaRecord[]> => {
  const data = await axios.get(`${apiUrl}/player/get-latest`);
  return data.data;
};

export const GetTopRatedPlayers = async (): Promise<RaRecord[]> => {
  const data = await axios.get(`${apiUrl}/player/get-top-rated`);
  return data.data;
};

export const UpdateSeasonTrophy = async (
  managedSeasonId: Identifier,
  seasonId: Identifier,
  trophies: Identifier[],
): Promise<string> => {
  const body = { managedSeasonId, seasonId, trophies };
  const data = await axios.post(`${apiUrl}/season-trophy/update-season-trophy`, body);
  return data.data;
};

export const HandleFavourite = async (
  playerId: Identifier,
  isFavourite: number,
): Promise<string> => {
  const body = {
    playerId,
    isFavourite,
  };
  const data = await axios.post(`${apiUrl}/player/handle-favourite`, body);
  return data.data;
};

export const ImportData = async (
  dataToCreate: Partial<RaRecord>,
  resource: string,
): Promise<string> => {
  const body = { dataToCreate };
  const data = await axios.post(
    `${apiUrl}/${resource === 'player' ? 'player/import-players' : 'team/import-teams'}`,
    body,
  );
  return data.data;
};

export const GetFifaInfo = async (): Promise<RaRecord[]> => {
  const data = await axios.get(`${apiUrl}/fifa/get-info`);
  return data.data;
};
