import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useRedirect,
  type CreateProps,
} from 'react-admin';
import { useIsAdmin } from '../../cms-core/src/hooks';
import CustomActions from '../../layout/CustomActions';

const TrophyCreate: React.FC<CreateProps> = (props) => {
  const redirect = useRedirect();
  const isAdmin = useIsAdmin();

  React.useEffect(() => {
    if (!isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect]);

  return (
    <Create
      redirect="list"
      actions={<CustomActions resource="trophy" hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="name" variant="filled" validate={required()} />
        <ReferenceInput source="fifaId" reference="fifa" sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput
            label="FIFA"
            variant="filled"
            optionText="name"
            optionValue="id"
            validate={required()}
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default TrophyCreate;
