import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { UserMenu, useTranslate } from 'react-admin';
import CustomLogout from './CustomLogout';
import { useLoggedUser } from '../hooks';
import type { CustomUserMenuProps } from '../interfaces';

const ChangePasswordDialog = React.lazy(() => import('../components/dialogs/ChangePasswordDialog'));

/**
 * React component that renders user menu actions (change password, logout) and display's login.
 *
 * @prop {React.FC[] | undefined} Items - Custom items passed from parent project.
 */
const CustomUserMenu: React.FC<CustomUserMenuProps> = ({ Items }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const translate = useTranslate();
  const { user } = useLoggedUser();

  const renderCustomItems = (): JSX.Element[] | null => {
    if (Items) {
      return Items.map((Item: React.FC, index: number) => {
        return <Item key={index} />;
      });
    } else return null;
  };

  return (
    <>
      <UserMenu>
        <MenuList>
          {user && (
            <MenuItem>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{user.login}</ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={() => setOpenDialog(true)}>
            <ListItemIcon>
              <LockIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{translate('actions.changePassword')}</ListItemText>
          </MenuItem>

          {renderCustomItems()}
          <CustomLogout />
        </MenuList>
      </UserMenu>
      {openDialog && <ChangePasswordDialog open={openDialog} setOpen={setOpenDialog} ownPassword />}
    </>
  );
};

export default CustomUserMenu;
