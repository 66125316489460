import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRedirect, useTranslate, type RaRecord } from 'react-admin';
import type { SquadPlayersSubsDialogProps, Player } from '../../../interfaces';
import { buildFifaIndexUrl } from '../../../utils';

const SquadPlayersSubsDialog: React.FC<SquadPlayersSubsDialogProps> = ({
  open,
  setOpen,
  playersInSquad,
  playerList,
  fifa,
}) => {
  const [subs, setSubs] = React.useState<RaRecord[]>([]);
  const redirect = useRedirect();
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const filtered = playersInSquad.filter((player: RaRecord) => player.sub === 1);
    const onlySubs = playerList.filter((player: Player) =>
      filtered.some((playerInSquad: RaRecord) => playerInSquad.playerId === player.id),
    );
    setSubs(onlySubs);
  }, [playersInSquad, playerList]);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{translate('resources.squad.fields.subs')}</DialogTitle>
        <DialogContent>
          <Paper sx={{ backgroundColor: '#d6d6c2', padding: '10px' }}>
            {subs.map((sub: RaRecord, index: number) => {
              return (
                <Paper
                  data-id={sub.id}
                  elevation={0}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '10px',
                    width: isSmall ? 'auto' : '50%',
                    padding: '10px',
                  }}
                  key={index}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={buildFifaIndexUrl(fifa, sub.fifaIndexId, 'players')}
                      onClick={() => redirect('show', 'player', sub.id)}
                      sx={{
                        width: 40,
                        height: 40,
                        margin: 'auto',
                        cursor: 'pointer',
                      }}
                    />
                    <Typography
                      onClick={() => redirect('show', 'player', sub.id)}
                      sx={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    >
                      {`${sub.firstName} ${sub.lastName ?? ''}`}
                    </Typography>
                  </Box>
                </Paper>
              );
            })}
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SquadPlayersSubsDialog;
