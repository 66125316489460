import React from 'react';
import FolderIcon from '@mui/icons-material/Folder';

const LogList = React.lazy(() => import('./List'));
const LogShow = React.lazy(() => import('./Show'));

const Log = {
  name: 'log',
  icon: FolderIcon,
  list: LogList,
  show: LogShow,
};

export default Log;
