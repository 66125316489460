import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { List, Datagrid, SimpleList, TextField, type ListProps, type RaRecord } from 'react-admin';
import CustomEmpty from '../../cms-core/src/layout/CustomEmpty';
import { fifaFilters } from '../../components/filters/fifa';
import CustomActions from '../../layout/CustomActions';

const FifaList: React.FC<ListProps> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomActions resource="fifa" hasFilters hasCreate />}
      filters={fifaFilters(isSmall)}
      empty={<CustomEmpty resource="fifa" />}
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      {...props}
    >
      {!isSmall ? (
        <Datagrid rowClick="edit" bulkActionButtons={<></>} isRowSelectable={() => false}>
          <TextField source="name" />
        </Datagrid>
      ) : (
        <SimpleList primaryText={(data: RaRecord) => data.name} linkType="edit" />
      )}
    </List>
  );
};

export default FifaList;
