import React from 'react';
import { Layout, type LayoutProps } from 'react-admin';
import CustomMenu from './CustomMenu';
import RefreshTokenButton from '../cms-core/src/components/buttons/RefreshTokenButton';
import AppBar from '../cms-core/src/layout/AppBar';
import { customMenuItems } from '../utils';

const AppBarWrapper: React.FC = () => <AppBar customItems={customMenuItems} />;

const CustomLayout: React.FC<LayoutProps> = (props) => {
  return (
    <Layout {...props} appBar={AppBarWrapper} menu={CustomMenu}>
      <RefreshTokenButton />
      {props.children}
    </Layout>
  );
};

export default CustomLayout;
