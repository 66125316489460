import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { Button } from 'react-admin';

const ChangePasswordDialog = React.lazy(() => import('../dialogs/ChangePasswordDialog'));

/**
 * React component that renders action button to display ChangePasswordDialog component.
 */
const ChangePasswordActionButton: React.FC = () => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  return (
    <>
      <Button label="actions.changePassword" onClick={() => setOpenDialog(true)}>
        <LockIcon />
      </Button>
      {openDialog && <ChangePasswordDialog open={openDialog} setOpen={setOpenDialog} />}
    </>
  );
};

export default ChangePasswordActionButton;
