import React from 'react';
import Link from '@mui/material/Link';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import {
  useRecordContext,
  useDataProvider,
  useNotify,
  type RaRecord,
  type Identifier,
} from 'react-admin';
import { useHandleApiError } from '../../cms-core/src/hooks';
import type { FifaIndexUrlFieldProps } from '../../interfaces';

const fifaIndexUrl = process.env.REACT_APP_FIFA_INDEX_URL ?? '';

const FifaIndexUrlField: React.FC<FifaIndexUrlFieldProps> = ({ type }) => {
  const [url, setUrl] = React.useState<string>('');
  const [fullName, setFullName] = React.useState<string>('');

  const notify = useNotify();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const handleError = useHandleApiError();

  const getFifa = React.useCallback(
    async (id: Identifier) => {
      const data = await dataProvider.getOne('fifa', { id });
      return data.data;
    },
    [dataProvider],
  );

  const formatName = (name: string): string => {
    return name
      .toLowerCase()
      .replace(/['’.,]/g, '')
      .replace(/\s+/g, '-');
  };

  React.useEffect(() => {
    if (record !== undefined) {
      const fifaId: Identifier = get(record, ['fifaId'], 0);
      const fifaIndexId: number = get(record, ['fifaIndexId'], 0);
      if (fifaIndexId === 99999999) return;
      if (type === 'player') {
        const firstName: string = get(record, ['firstName'], '');
        const lastName: string | null = get(record, ['lastName'], '');
        const formattedFirstName = formatName(firstName);
        const formattedLastName = formatName(lastName ?? '');
        getFifa(fifaId)
          .then((resp: RaRecord) => {
            const fifaName = resp.name.toLowerCase().replace(/\s+/g, '');
            const finalUrl =
              formattedLastName.length > 0
                ? `${fifaIndexUrl}/player/${fifaIndexId}/${formattedFirstName}-${formattedLastName}/${fifaName}`
                : `${fifaIndexUrl}/player/${fifaIndexId}/${formattedFirstName}/${fifaName}`;
            setFullName(formattedLastName.length > 0 ? `${firstName} ${lastName}` : firstName);
            setUrl(finalUrl);
          })
          .catch(async (err: AxiosError) => {
            await handleError(err);
          });
      } else {
        const teamName: string = get(record, ['name'], '');
        const formattedName = formatName(teamName);
        getFifa(fifaId)
          .then((resp: RaRecord) => {
            const fifaName = resp.name.toLowerCase().replace(/\s+/g, '');
            const finalUrl = `${fifaIndexUrl}/team/${fifaIndexId}/${formattedName}/${fifaName}`;
            setFullName(teamName);
            setUrl(finalUrl);
          })
          .catch(async (err: AxiosError) => {
            await handleError(err);
          });
      }
    }
  }, [getFifa, notify, record, type, handleError]);

  return (
    <>
      {url.length > 0 ? (
        <Link href={url} variant="body2" target="_blank" rel="noopener noreferrer">
          {fullName}
        </Link>
      ) : null}
    </>
  );
};

export default FifaIndexUrlField;
