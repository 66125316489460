import type { AvailableLocale } from '../interfaces';

/**
 * Replaces '_' characters for '-' in resource name.
 *
 * @param {string} resource - Resource name.
 * @returns {string} Transformed resource name.
 *
 * @example
 * const newResource = transformResource('poll_option');
 */
export const transformResource = (resource: string): string => {
  return resource.includes('_') ? resource.replace(/_/g, '-') : resource;
};

/**
 * Stores available locales, can be expanded.
 */
export const locales: AvailableLocale[] = [
  {
    locale: 'en',
    name: 'English',
  },
  {
    locale: 'cs',
    name: 'Čeština',
  },
  {
    locale: 'de',
    name: 'Deutsch',
  },
  {
    locale: 'fr',
    name: 'Français',
  },
];
