import polyglotI18nProvider from 'ra-i18n-polyglot';
import { type TranslationMessages } from 'react-admin';
import { getLocaleTranslations } from '../../../utils';

const defaultLocale = process.env.REACT_APP_DEFAULT_LOCALE ?? 'en';

/**
 * Handles translations and localization.
 */
const i18nProvider = polyglotI18nProvider(
  (locale: string) => getLocaleTranslations(locale) as TranslationMessages,
  defaultLocale,
);

export default i18nProvider;
