import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  List,
  Datagrid,
  SimpleList,
  TextField,
  FunctionField,
  ReferenceField,
  NumberField,
  type ListProps,
} from 'react-admin';
import CustomEmpty from '../../cms-core/src/layout/CustomEmpty';
import AvatarField from '../../components/fields/AvatarField';
import { playerFilters } from '../../components/filters/player';
import type { Player } from '../../interfaces';
import CustomActions from '../../layout/CustomActions';
import CustomBulkActions from '../../layout/CustomBulkActions';

const PlayerList: React.FC<ListProps> = (props) => {
  const defaultFifaFilter: string = localStorage.getItem('selected-fifa') ?? '';
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <List
      actions={<CustomActions hasFilters hasImportCreate resource="player" />}
      filters={playerFilters(isSmall)}
      filterDefaultValues={
        defaultFifaFilter.length > 0 ? { fifaId: parseInt(defaultFifaFilter) } : {}
      }
      empty={<CustomEmpty resource="player" />}
      sort={{
        field: 'rating',
        order: 'DESC',
      }}
      {...props}
    >
      {!isSmall ? (
        <Datagrid rowClick="show" bulkActionButtons={<CustomBulkActions />}>
          <FunctionField
            label="Name"
            render={(record: Player) =>
              record.lastName !== null ? `${record.firstName} ${record.lastName}` : record.firstName
            }
          />
          <AvatarField source="fifaIndexId" type="players" size={50} label="" />
          <ReferenceField source="teamId" reference="team" link="show">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="rating" />
          <ReferenceField source="positionId" reference="position" link="show">
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      ) : (
        <SimpleList
          primaryText={(data) =>
            data.lastName !== null ? `${data.firstName} ${data.lastName}` : data.firstName
          }
          secondaryText={(data) => data.rating}
          linkType="show"
        />
      )}
    </List>
  );
};

export default PlayerList;
