import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import type { AxiosError } from 'axios';
import get from 'lodash/get';
import { useRecordContext, useDataProvider, type RaRecord } from 'react-admin';
import { useController } from 'react-hook-form';
import { useHandleApiError } from '../../cms-core/src/hooks';
import type { FifaInputProps } from '../../interfaces';

const FifaInput: React.FC<FifaInputProps> = ({
  source,
  label,
  selectedVal,
  setSelectedVal,
  disabled,
}) => {
  const [fifas, setFifas] = React.useState<RaRecord[]>([]);

  const input = useController({ name: source }).field;
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const handleError = useHandleApiError();

  const handleChange = (event: SelectChangeEvent): void => {
    setSelectedVal(event.target.value);
    input.onChange(event.target.value);
  };

  const getFifas = React.useCallback(async () => {
    const data = await dataProvider.getList('fifa', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    setFifas(data.data);
  }, [dataProvider]);

  React.useEffect(() => {
    getFifas().catch(async (err: AxiosError) => {
      await handleError(err);
    });
    if (record !== undefined) setSelectedVal(get(record, 'fifaId', ''));
  }, [getFifas, handleError, record, setSelectedVal]);

  return (
    <>
      {fifas.length > 0 ? (
        <FormControl
          variant="filled"
          sx={{
            width: 219,
            height: '71.91px',
          }}
        >
          <InputLabel>{`${label} *`}</InputLabel>
          <Select
            value={selectedVal}
            label={`${label} *`}
            onChange={handleChange}
            disabled={disabled}
          >
            {fifas.map((item: RaRecord, index: number) => {
              return (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
    </>
  );
};

export default FifaInput;
