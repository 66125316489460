import { SearchInput, ReferenceInput, SelectInput } from 'react-admin';
import IsFavouriteInput from '../../inputs/IsFavouriteInput';

export const playerFilters = (isSmall: boolean): JSX.Element[] => {
  return [
    <SearchInput
      source="lastName"
      variant="filled"
      key="lastName"
      alwaysOn={!isSmall}
      sx={{ marginLeft: isSmall ? '10px' : 0 }}
    />,
    <ReferenceInput
      source="fifaId"
      reference="fifa"
      key="fifaId"
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      alwaysOn={!isSmall}
    >
      <SelectInput
        label="FIFA"
        variant="filled"
        optionValue="id"
        optionText="name"
        sx={{ marginLeft: isSmall ? '10px' : 0 }}
      />
    </ReferenceInput>,
    <ReferenceInput source="positionId" reference="position" key="positionId" alwaysOn={!isSmall}>
      <SelectInput
        source="name"
        variant="filled"
        optionText="name"
        optionValue="id"
        sx={{ marginLeft: isSmall ? '10px' : 0 }}
      />
    </ReferenceInput>,
    <IsFavouriteInput key="is_favourite" source="is_favourite" alwaysOn={!isSmall} />,
  ];
};
