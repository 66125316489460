import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  type CreateProps,
} from 'react-admin';
import CustomActions from '../../layout/CustomActions';

const ManagerCreate: React.FC<CreateProps> = (props) => {
  return (
    <Create
      redirect="show"
      actions={<CustomActions resource="manager" hasList />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="firstName" variant="filled" validate={required()} />
        <TextInput source="lastName" variant="filled" validate={required()} />
        <ReferenceInput source="fifaId" reference="fifa" sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput
            label="FIFA"
            variant="filled"
            optionText="name"
            optionValue="id"
            validate={required()}
            sx={{
              width: 219,
            }}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ManagerCreate;
