import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { AxiosError } from 'axios';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Pagination,
  NumberField,
  ReferenceField,
  TextField,
  useList,
  useDataProvider,
  useShowContext,
  useTranslate,
  type RaRecord,
} from 'react-admin';
import AvatarField from './AvatarField';
import { useHandleApiError } from '../../cms-core/src/hooks';
import type { EmbeddedListProps } from '../../interfaces';

const EmbeddedList: React.FC<EmbeddedListProps> = ({ resource, filterBy, orderBy }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [entities, setEntities] = React.useState<RaRecord[]>([]);
  const [filterName, setFilterName] = React.useState<string>('');
  const [entitiesCopy, setEntitiesCopy] = React.useState<RaRecord[]>([]);

  const translate = useTranslate();
  const listContext = useList({
    data: entities,
    perPage: 10,
  });
  const { record } = useShowContext();
  const dataProvider = useDataProvider();
  const handleError = useHandleApiError();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilterName(event.target.value);
    if (event.target.value.length === 0) setEntities(entitiesCopy);
    else {
      const filtered: RaRecord[] = entitiesCopy.filter((player: RaRecord) =>
        player.lastName !== null
          ? `${player.firstName} ${player.lastName}`
          : player.firstName.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()),
      );
      setEntities(filtered);
    }
  };

  const getPlayers = React.useCallback(async () => {
    const data = await dataProvider.getList(resource, {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: orderBy, order: 'DESC' },
      filter: {},
    });
    const filtered = data.data.filter((entity: RaRecord) => entity[filterBy] === record?.id);
    setEntities(filtered);
    setEntitiesCopy(filtered);
  }, [dataProvider, filterBy, orderBy, record?.id, resource]);

  React.useEffect(() => {
    getPlayers()
      .catch(async (err: AxiosError) => {
        await handleError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getPlayers, handleError]);

  return !loading ? (
    <ListContextProvider value={listContext}>
      {resource === 'player' ? (
        <MuiTextField
          label={translate('resources.player.fields.lastName')}
          variant="filled"
          size="small"
          value={filterName}
          onChange={handleFilterName}
          sx={{
            width: 219,
          }}
        />
      ) : null}
      <Datagrid bulkActionButtons={false} rowClick="show" resource={resource}>
        {resource === 'player' && (
          <FunctionField
            label="resources.player.fields.fullName"
            render={(record: RaRecord) => `${record.firstName} ${record.lastName ?? ''}`}
          />
        )}
        {resource === 'player' && !isSmall ? (
          <AvatarField source="fifaIndexId" type="players" size={50} label="" />
        ) : null}
        {resource === 'player' && <NumberField source="rating" />}
        {resource === 'player' && (
          <ReferenceField source="positionId" reference="position" link="show">
            <TextField source="name" />
          </ReferenceField>
        )}
      </Datagrid>
      <Pagination rowsPerPageOptions={[5, 10, 25]} />
    </ListContextProvider>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress color="primary" size={50} />
    </Box>
  );
};

export default EmbeddedList;
