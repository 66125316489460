import axios, { type AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import type { Identifier, RaRecord } from 'react-admin';
import type { GetListQueryParams, LoggedUser } from '../interfaces';
import { transformResource } from '../utils';

const apiUrl = process.env.REACT_APP_API_URL ?? '';
axios.defaults.withCredentials = true;

/* CRUD */

/**
 * Returns list of entities.
 *
 * @param {string} resource - The type of entity.
 * @param {GetListQueryParams} query - List parameters.
 * @returns {Promise<AxiosResponse>} The list data.
 */
export const List = async (resource: string, query: GetListQueryParams): Promise<AxiosResponse> => {
  resource = transformResource(resource);
  const data = await axios.get(`${apiUrl}/${resource}/get-list?${stringify(query)}`);
  return data;
};

/**
 * Returns single entity.
 *
 * @param {string} resource - The type of entity.
 * @param {Identifier} id - ID of specific entity.
 * @returns {Promise<RaRecord[]>} Array with single specific entity.
 */
export const GetOne = async (resource: string, id: Identifier): Promise<RaRecord[]> => {
  resource = transformResource(resource);
  const data = await axios.get(`${apiUrl}/${resource}/get-one?id=${id}`);
  return data.data;
};

/**
 * Returns multiple entities.
 *
 * @param {string} resource - The type of entity.
 * @param {Identifier[]} ids - IDs of specific entities.
 * @returns {Promise<RaRecord[]>} Array with multiple specific entities.
 */
export const GetMany = async (resource: string, ids: Identifier[]): Promise<RaRecord[]> => {
  resource = transformResource(resource);
  const data = await axios.get(`${apiUrl}/${resource}/get-many?ids=${ids.join(',')}`);
  return data.data;
};

/**
 * Returns multiple references.
 *
 * @param {string} resource - The type of entity.
 * @param {GetListQueryParams} query - List parameters.
 * @returns {Promise<RaRecord[]>} The list of references.
 */
export const GetManyReference = async (
  resource: string,
  query: GetListQueryParams,
): Promise<RaRecord[]> => {
  resource = transformResource(resource);
  const data = await axios.get(`${apiUrl}/${resource}/get-many-reference?${stringify(query)}`);
  return data.data;
};

/**
 * Returns updated entity.
 *
 * @param {string} resource - The type of entity.
 * @param {Partial<RaRecord>} body - Updated entity data.
 * @returns {Promise<RaRecord>} Single updated entity.
 */
export const Update = async (resource: string, body: Partial<RaRecord>): Promise<RaRecord> => {
  resource = transformResource(resource);
  const data = await axios.put(`${apiUrl}/${resource}/edit`, body);
  return data.data;
};

/**
 * Returns created entity.
 *
 * @param {string} resource - The type of entity.
 * @param {Partial<RaRecord>} body - New entity data.
 * @returns {Promise<RaRecord[]>} Single created entity.
 */
export const Create = async (resource: string, body: Partial<RaRecord>): Promise<RaRecord> => {
  resource = transformResource(resource);
  const data = await axios.post(`${apiUrl}/${resource}/create`, body);
  return data.data;
};

/**
 * Deletes specific entity.
 *
 * @param {string} resource - The type of entity.
 * @param {Identifier} id - ID of entity to delete.
 * @returns {Promise<string>} Confirmation string that entity was deleted.
 */
export const Delete = async (resource: string, id: Identifier): Promise<string> => {
  resource = transformResource(resource);
  const data = await axios.delete(`${apiUrl}/${resource}/delete?id=${id}`);
  return data.data;
};

/**
 * Deletes multiple specific entities.
 *
 * @param {string} resource - The type of entity.
 * @param {Identifier[]} ids - IDs of entities to delete.
 * @returns {Promise<string>} Confirmation string that entities were deleted.
 */
export const DeleteMany = async (resource: string, ids: Identifier[]): Promise<string> => {
  resource = transformResource(resource);
  const body = {
    ids,
  };
  const data = await axios.post(`${apiUrl}/${resource}/delete-many`, body);
  return data.data;
};

/* OTHER ENDPOINTS */

/**
 * Checks if user is logged.
 *
 * @returns {Promise<boolean>} Boolean value if user is logged.
 */
export const IsLogged = async (): Promise<boolean> => {
  const data = await axios.get(`${apiUrl}/auth/is-logged`);
  return data.data;
};

/**
 * Handles user login.
 *
 * @param {string} login - User login
 * @param {string} password - User password.
 * @returns {Promise<string>} Logged user's username.
 */
export const Login = async (login: string, password: string): Promise<string> => {
  const body = {
    login,
    password,
  };
  const data = await axios.post(`${apiUrl}/auth/login`, body);
  return data.data;
};

/**
 * Handles user logout.
 *
 * @returns {Promise<string>} Confirmation string that user was logged out.
 */
export const Logout = async (): Promise<string> => {
  const data = await axios.get(`${apiUrl}/auth/logout`);
  return data.data;
};

/**
 * Changes user's password
 *
 * @param {string} newPass - New password.
 * @param {string} newPassAgain - New password again.
 * @param {Identifier} userId - ID of user.
 * @returns {Promise<string>} Confirmation string that password was changed.
 */
export const ChangeUserPassword = async (
  newPass: string,
  newPassAgain: string,
  userId: Identifier,
): Promise<string> => {
  const body = {
    newPass,
    newPassAgain,
    userId,
  };
  const data = await axios.post(`${apiUrl}/user/change-user-password`, body);
  return data.data;
};

/**
 * Changes logged user's password
 *
 * @param {string} newPass - New password.
 * @param {string} newPassAgain - New password again.
 * @param {string} oldPass - Old password.
 * @returns {Promise<string>} Confirmation string that password was changed.
 */
export const ChangeOwnPassword = async (
  newPass: string,
  newPassAgain: string,
  oldPass: string,
): Promise<string> => {
  const body = {
    newPass,
    newPassAgain,
    oldPass,
  };
  const data = await axios.post(`${apiUrl}/user/change-own-password`, body);
  return data.data;
};

/**
 * Returns logged user's data.
 *
 * @returns {Promise<LoggedUser>} Logged user.
 */
export const GetLoggedUser = async (): Promise<LoggedUser> => {
  const data = await axios.get(`${apiUrl}/auth/get-logged-user`);
  return data.data;
};

/**
 * Refreshes user's session.
 *
 * @returns {Promise<string>} Confirmation string.
 */
export const RefreshToken = async (): Promise<string> => {
  const data = await axios.post(`${apiUrl}/auth/refresh-token`);
  return data.data;
};
