import React from 'react';
import { type Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AppBar as RaAppBar,
  TitlePortal,
  LocalesMenuButton,
  useTranslate,
  ToggleThemeButton,
  LoadingIndicator,
} from 'react-admin';
import CustomUserMenu from './CustomUserMenu';
import { useGetAvailableLocales } from '../hooks';
import type { AvailableLocale, CustomAppBarProps } from '../interfaces';

/**
 * React component that displays custom MUI app bar.
 *
 * @prop {boolean | undefined} hasThemeButton - Indicates if ToggleThemeButton should be displayed.
 * @prop {React.FC[] | undefined} customItems - Custom items to be passed to UserMenu component.
 */
const AppBar: React.FC<CustomAppBarProps> = ({ hasThemeButton, customItems }) => {
  const translate = useTranslate();
  const availableLocales = useGetAvailableLocales();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const translatedLocales = availableLocales.map((locale: AvailableLocale) => {
    return {
      ...locale,
      name: translate(`langs.${locale.locale}`),
    };
  });

  return (
    <RaAppBar
      color="primary"
      toolbar={
        isSmall ? (
          <></>
        ) : (
          <>
            {hasThemeButton && <ToggleThemeButton data-testid="toggle-theme-button" />}
            <LoadingIndicator data-testid="loading-indicator" />
          </>
        )
      }
      userMenu={<CustomUserMenu data-testid="custom-user-menu" Items={customItems} />}
      sx={{ color: '#fff' }}
    >
      <TitlePortal data-testid="title-portal" />
      {availableLocales.length > 1 && (
        <LocalesMenuButton data-testid="locales-menu-button" languages={translatedLocales} />
      )}
    </RaAppBar>
  );
};

export default AppBar;
