import React from 'react';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import { useRecordContext, useTranslate } from 'react-admin';
import type { PlayerTitleProps } from '../interfaces';

const PlayerTitle: React.FC<PlayerTitleProps> = ({ view }) => {
  const translate = useTranslate();
  const record = useRecordContext();

  const title = React.useMemo(() => {
    if (!record) return '';
    const firstName = get(record, ['firstName'], '');
    const lastName = get(record, ['lastName'], '');

    const translatedResourceName = translate('resources.player.name', {
      _: '',
      smart_count: 1,
    });
    const translatedEdit = translate('resources.player.edit');

    const newTitle =
      view === 'show'
        ? `${translatedResourceName} ${firstName} ${lastName ?? ''}`
        : `${translatedEdit} ${firstName} ${lastName ?? ''}`;

    return newTitle;
  }, [record, translate, view]);

  return (
    <Typography variant="h6" color="#fff">
      {title}
    </Typography>
  );
};

export default PlayerTitle;
