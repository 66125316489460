import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  required,
  useRedirect,
  useTranslate,
  type EditProps,
} from 'react-admin';
import { useIsAdmin } from '../../cms-core/src/hooks';
import CustomTitle from '../../cms-core/src/layout/CustomTitle';
import FifaInput from '../../components/inputs/FifaInput';
import RivalTeamInput from '../../components/inputs/RivalTeamInput';
import CustomActions from '../../layout/CustomActions';
import { teamTransformData } from '../../utils';

const TeamEdit: React.FC<EditProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  const redirect = useRedirect();
  const isAdmin = useIsAdmin();
  const translate = useTranslate();

  React.useEffect(() => {
    if (!isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect]);

  return (
    <Edit
      redirect="show"
      title={<CustomTitle resource="team" source="name" view="edit" />}
      actions={<CustomActions resource="team" hasShow hasList />}
      transform={teamTransformData}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="name" variant="filled" validate={required()} />
        <NumberInput
          source="budget"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0 } }}
        />
        <NumberInput
          source="fifaIndexId"
          variant="filled"
          validate={required()}
          InputProps={{ inputProps: { min: 0 } }}
        />
        <FifaInput
          source="fifaId"
          label={translate('resources.team.fields.fifaId')}
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) =>
            formData.fifaId !== undefined && (
              <>
                <RivalTeamInput
                  source="rivalTeamId"
                  label={translate('resources.team.fields.rivalTeamId')}
                  view="edit"
                  fifa={formData.fifaId}
                />
                <ReferenceInput
                  source="squadId"
                  reference="squad"
                  filter={{ fifaId: formData.fifaId }}
                >
                  <SelectInput
                    source="name"
                    variant="filled"
                    label="Squad"
                    sx={{
                      width: 219,
                    }}
                  />
                </ReferenceInput>
              </>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default TeamEdit;
