import React from 'react';
import type { AxiosError } from 'axios';
import {
  Edit,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  FormDataConsumer,
  required,
  useDataProvider,
  useNotify,
  useRedirect,
  type EditProps,
  type RaRecord,
} from 'react-admin';
import { useIsAdmin, useHandleApiError } from '../../cms-core/src/hooks';
import CustomTitle from '../../cms-core/src/layout/CustomTitle';
import FifaInput from '../../components/inputs/FifaInput';
import CustomActions from '../../layout/CustomActions';

const CareerEdit: React.FC<EditProps> = (props) => {
  const [selectedVal, setSelectedVal] = React.useState<string>('');
  const [managers, setManagers] = React.useState<RaRecord[]>([]);
  const [managersCopy, setManagersCopy] = React.useState<RaRecord[]>([]);

  const notify = useNotify();
  const isAdmin = useIsAdmin();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const handleError = useHandleApiError();

  const getManagers = React.useCallback(async () => {
    const data = await dataProvider.getList('manager', {
      pagination: { page: 1, perPage: 100000 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    });
    setManagers(data.data);
    setManagersCopy(data.data);
  }, [dataProvider]);

  React.useEffect(() => {
    getManagers().catch(async (err: AxiosError) => {
      await handleError(err);
    });
  }, [getManagers, notify, handleError]);

  React.useEffect(() => {
    if (parseInt(selectedVal) > 0) {
      const filtered = managersCopy.filter(
        (manager: RaRecord) => manager.fifaId === parseInt(selectedVal),
      );
      setManagers(filtered);
    }
  }, [selectedVal, managersCopy]);

  React.useEffect(() => {
    if (!isAdmin) redirect('/unauthorized');
  }, [isAdmin, redirect]);

  return (
    <Edit
      redirect="show"
      actions={<CustomActions resource="career" hasShow hasList />}
      title={<CustomTitle resource="career" source="name" view="edit" />}
      sx={{
        marginBottom: '1rem',
      }}
      {...props}
    >
      <SimpleForm spacing={1}>
        <TextInput source="name" variant="filled" validate={required()} />
        <FifaInput
          source="fifaId"
          label="FIFA"
          selectedVal={selectedVal}
          setSelectedVal={setSelectedVal}
          disabled
        />
        <FormDataConsumer<{ fifaId: number }>>
          {({ formData }) =>
            formData.fifaId !== undefined && (
              <AutocompleteInput
                source="managerId"
                choices={managers}
                variant="filled"
                validate={required()}
                optionText={(record: RaRecord) => `${record.firstName} ${record.lastName}`}
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
                shouldRenderSuggestions={(val: string) => {
                  return val.trim().length > 2;
                }}
                noOptionsText="Type 3 characters"
                sx={{
                  width: 219,
                }}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default CareerEdit;
