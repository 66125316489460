import React from 'react';
import {
  Show,
  TabbedShowLayout,
  TextField,
  NumberField,
  ReferenceField,
  type ShowProps,
} from 'react-admin';
import CustomTitle from '../../cms-core/src/layout/CustomTitle';
import SquadPlayersField from '../../components/fields/squadPlayersField';
import CustomActions from '../../layout/CustomActions';

const SquadShow: React.FC<ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={<CustomTitle resource="squad" source="name" view="show" />}
      actions={<CustomActions resource="squad" hasEdit hasList />}
      sx={{ marginBottom: '1rem' }}
    >
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
          <NumberField source="id" />
          <TextField source="name" />
          <ReferenceField source="formationId" reference="formation" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="fifaId" label="FIFA" reference="fifa" link={false}>
            <TextField source="name" />
          </ReferenceField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="squad">
          <SquadPlayersField />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default SquadShow;
