import React from 'react';
import { BulkDeleteButton } from 'react-admin';
import { useIsAdmin } from '../cms-core/src/hooks';

const CustomBulkActions: React.FC = () => {
  const isAdmin = useIsAdmin();
  return (
    <>
      <BulkDeleteButton disabled={!isAdmin} />
    </>
  );
};

export default CustomBulkActions;
