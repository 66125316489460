import enMessages from 'ra-language-english';
import type { Translation } from '../../interfaces';

/**
 * English translations
 */
export const enCore: Translation = {
  ...enMessages,
  resources: {
    user: {
      name: 'User |||| Users',
      edit: 'Edit',
      create: 'Create',
      empty: 'No users created yet',
      fields: {
        id: 'ID',
        login: 'Username',
        newPass: 'New password',
        newPassAgain: 'New password (again)',
        oldPass: 'Old password',
      },
    },
    log: {
      name: 'Log |||| Logs',
      empty: 'No logs present for current week',
      fields: {
        endpoint: 'Source',
        timestamp: 'Time',
        status: 'Status',
        message: 'Message',
        onlyToday: 'Only today',
      },
    },
    role: {
      name: 'Role',
      edit: 'Edit',
      create: 'Create',
      empty: 'No rols created yet',
      fields: {
        name: 'Name',
      },
    },
  },
  actions: {
    homepage: 'Home',
    changePassword: 'Change password',
  },
  langs: {
    cs: 'Czech',
    en: 'English',
  },
  errors: {
    serverError: 'Server Error',
    userLoggedOut: 'User logged out',
    wrongCredentials: 'Wrong credentials',
    passwordNoMatch: 'Passwords do not match',
    invalidData: 'Invalid data',
    notFound: 'Entita nenalezena',
    duplicateEntity: 'Duplicate entity',
    loggedUserContext: 'useLoggedUser must be used within a LoggedUserProvider',
    insufficientPermissions: 'Insufficient permissions',
    insufficientPermissionsDesc: 'You do not have permission to display this content.',
  },
  success: {
    passwordChanged: 'Password was successfully changed',
    refreshTokenSet: 'Session was successfully renewed',
  },
};
