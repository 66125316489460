import { SearchInput, ReferenceInput, SelectInput } from 'react-admin';

export const trophyFilters = (isSmall: boolean): JSX.Element[] => {
  return [
    <SearchInput
      source="name"
      variant="filled"
      key="name"
      alwaysOn={!isSmall}
      sx={{ marginLeft: isSmall ? '10px' : 0 }}
    />,
    <ReferenceInput
      source="fifaId"
      reference="fifa"
      key="fifaId"
      sort={{
        field: 'name',
        order: 'ASC',
      }}
      alwaysOn={!isSmall}
    >
      <SelectInput
        label="FIFA"
        variant="filled"
        optionValue="id"
        optionText="name"
        sx={{ marginLeft: isSmall ? '10px' : 0 }}
      />
    </ReferenceInput>,
  ];
};
